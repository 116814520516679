import inter from '../assets/covers/interior.jpg'
import ext from '../assets/covers/exterior.jpg'
import t3d from '../assets/covers/render.jpg'
import ani from '../assets/covers/anim.jpg'
import vr from '../assets/covers/vr.jpg'



export const links = [
  {
    id: "na1Li1",
    text: "Services",
    url: "/services",
    ariaLabel: "services page",
  },
  {
    id: "na1Li2",
    text: "Projects",
    url: "/projects",
    ariaLabel: "projects page",
  },
  {
    id: "na1Li3",
    text: "FAQ",
    url: "/faq",
    ariaLabel: "FAQ page",
  },
  {
    id: "na1Li4",
    text: "About",
    url: "/about",
    ariaLabel: "About page",
  },
  {
    id: "na1Li5",
    text: "Estimate project",
    url: "/contact",
    ariaLabel: "contact page",
  },
];

export const socials = [
  {
    id: "so1Li1",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="14.182"
        viewBox="0 0 20 14.182"
      >
        <g
          id="Group_181"
          data-name="Group 181"
          transform="translate(-6133.874 676.783)"
        >
          <path
            id="Path_207"
            data-name="Path 207"
            d="M6149.341-668.725a4.091,4.091,0,0,1-2.565-1.023l.191-.9.008-.033c.17-.953.708-2.552,2.366-2.552a2.257,2.257,0,0,1,2.254,2.254,2.26,2.26,0,0,1-2.254,2.249Zm0-6.786a4.593,4.593,0,0,0-4.425,3.638,18.642,18.642,0,0,1-2.242-4.91H6140.4v5.929a2.125,2.125,0,0,1-2.121,2.121,2.126,2.126,0,0,1-2.122-2.121v-5.929h-2.278v5.929a4.411,4.411,0,0,0,4.4,4.421,4.424,4.424,0,0,0,4.4-4.421v-.995a14.181,14.181,0,0,0,1.645,2.685l-1.4,6.563h2.332l1.011-4.76a5.618,5.618,0,0,0,3.075.924,4.551,4.551,0,0,0,4.533-4.546,4.539,4.539,0,0,0-4.533-4.528Z"
            fill="#a1a1a6"
          />
        </g>
      </svg>
    ),
    url: "https://upwork.com",
    ariaLabel: "Upwork",
  },
  {
    id: "so1Li2",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_175" data-name="Group 175" transform="translate(-6133.874 642.601)">
          <path id="Path_197" data-name="Path 197" d="M6150.944-642.6H6136.8a2.931,2.931,0,0,0-2.929,2.93v14.141a2.93,2.93,0,0,0,2.929,2.929h14.141a2.931,2.931,0,0,0,2.93-2.929v-14.141A2.932,2.932,0,0,0,6150.944-642.6Zm-7.07,15.572a5.571,5.571,0,0,1-5.572-5.572,5.572,5.572,0,0,1,5.572-5.572,5.571,5.571,0,0,1,5.571,5.572A5.571,5.571,0,0,1,6143.874-627.029Zm6.445-10.7a1.28,1.28,0,0,1-1.281-1.281,1.281,1.281,0,0,1,1.281-1.281,1.282,1.282,0,0,1,1.281,1.281A1.281,1.281,0,0,1,6150.319-637.726Z" fill="#d7d7d9"/>
          <circle id="Ellipse_40" data-name="Ellipse 40" cx="3.976" cy="3.976" r="3.976" transform="translate(6139.897 -636.538)" fill="#d7d7d9"/>
        </g>
      </svg>

    ),
    url: "https://instagram.com",
    ariaLabel: "Instagram",
  },
  {
    id: "so1Li3",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="12.589"
        viewBox="0 0 20 12.589"
      >
        <g
          id="Group_481"
          data-name="Group 481"
          transform="translate(-24.842 -486.729)"
        >
          <path
            id="Path_203"
            data-name="Path 203"
            d="M6144.4-593.873a3.422,3.422,0,0,0-.4-1.265,2.766,2.766,0,0,0-.631-.763,3.137,3.137,0,0,0-.855-.5,2.646,2.646,0,0,0-.3-.106c-.01,0-.025-.005-.027-.016s.013-.019.024-.024c.036-.018.071-.039.106-.058a3.187,3.187,0,0,0,.883-.686,2.386,2.386,0,0,0,.495-.865,2.972,2.972,0,0,0,.138-.819,4.574,4.574,0,0,0-.03-.731,2.89,2.89,0,0,0-.33-1.063,2.414,2.414,0,0,0-.613-.728,3.017,3.017,0,0,0-.867-.472,5.482,5.482,0,0,0-1.388-.281c-.19-.017-.381-.018-.572-.018h-6.142a.014.014,0,0,0-.012.005.012.012,0,0,0,0,.01v12.533a.073.073,0,0,0,0,.041h6.334c.08-.008.16,0,.24-.008.122-.006.244-.008.364-.024a6.737,6.737,0,0,0,.833-.166,4.607,4.607,0,0,0,1.03-.412,3.393,3.393,0,0,0,.984-.791,3.265,3.265,0,0,0,.52-.849,3.536,3.536,0,0,0,.248-1.23A4.433,4.433,0,0,0,6144.4-593.873Zm-7.762-6.2c0-.028,0-.045.038-.044.882,0,1.764,0,2.646,0a2.848,2.848,0,0,1,.946.146,1.234,1.234,0,0,1,.739.638,1.424,1.424,0,0,1,.125.573,1.945,1.945,0,0,1-.092.7,1.153,1.153,0,0,1-.592.662,2.031,2.031,0,0,1-.778.211c-.107.009-.213.008-.32.009h-1.209c-.486,0-.972,0-1.458,0-.037,0-.046-.01-.046-.046Q6136.644-598.645,6136.643-600.073Zm4.961,7.044a1.337,1.337,0,0,1-.807.982,2.541,2.541,0,0,1-.751.19,5.075,5.075,0,0,1-.591.025q-1.381,0-2.763,0c-.04,0-.05-.01-.05-.05,0-.56,0-1.12,0-1.679s0-1.12,0-1.68c0-.038.01-.048.049-.048h2.95a2.89,2.89,0,0,1,.887.122,1.781,1.781,0,0,1,.637.343,1.315,1.315,0,0,1,.412.7A2.432,2.432,0,0,1,6141.6-593.029Z"
            transform="translate(-6109.032 1089)"
            fill="#a1a1a6"
          />
          <path
            id="Path_204"
            data-name="Path 204"
            d="M6153.759-595.539a5.272,5.272,0,0,0-.665-1.756,4.074,4.074,0,0,0-1.769-1.592,4.058,4.058,0,0,0-1.345-.356,5.429,5.429,0,0,0-1.378.037,4.4,4.4,0,0,0-1.212.373,4.353,4.353,0,0,0-1.833,1.593,4.924,4.924,0,0,0-.764,2.391,6.057,6.057,0,0,0,.073,1.428,4.849,4.849,0,0,0,.627,1.707,3.951,3.951,0,0,0,1.323,1.348,4.7,4.7,0,0,0,2.173.669,2.029,2.029,0,0,1,.22.015h.458a.073.073,0,0,1,.041-.006c.119,0,.238-.014.357-.026a4.768,4.768,0,0,0,1.326-.318,3.51,3.51,0,0,0,1.573-1.2,5.2,5.2,0,0,0,.688-1.357l.046-.139-.025,0h-2.146c-.027,0-.035.011-.042.033a.894.894,0,0,1-.12.246,2.07,2.07,0,0,1-1.155.811,2.82,2.82,0,0,1-1.514-.013,1.7,1.7,0,0,1-1.21-1.093,3.175,3.175,0,0,1-.177-.781c-.011-.1-.016-.2-.025-.3,0-.023,0-.034.029-.031a.228.228,0,0,0,.037,0h6.484c.02,0,.025-.008.026-.027,0-.036.005-.072.006-.108A6.512,6.512,0,0,0,6153.759-595.539Zm-2.445.094c-.095,0-.189,0-.284,0h-3.7c-.053,0-.053,0-.047-.051a2.472,2.472,0,0,1,.191-.763,1.823,1.823,0,0,1,1.333-1.07,2.618,2.618,0,0,1,1.213.026,1.478,1.478,0,0,1,.926.71,3.23,3.23,0,0,1,.367.967c.011.046.018.093.03.139S6151.35-595.444,6151.314-595.445Z"
            transform="translate(-6109.032 1089)"
            fill="#a1a1a6"
          />
          <path
            id="Path_205"
            data-name="Path 205"
            d="M6133.874-602.267l.009,0v12.583s-.009,0-.008,0v-12.581Z"
            transform="translate(-6109.032 1089)"
            fill="#a1a1a6"
          />
          <path
            id="Path_206"
            data-name="Path 206"
            d="M6149.4-600.217h-2.5c-.04,0-.05-.009-.05-.049q0-.658,0-1.317c0-.036.009-.046.046-.046h5c.039,0,.048.011.047.048q0,.659,0,1.317c0,.037-.008.047-.047.047Z"
            transform="translate(-6109.032 1089)"
            fill="#a1a1a6"
          />
        </g>
      </svg>
    ),
    url: "https://behance.com",
    ariaLabel: "Behance",
  },
  {
    id: "so1Li4",
    icon: (
      <svg
        id="Group_713"
        data-name="Group 713"
        xmlns="http://www.w3.org/2000/svg"
        width="28.028"
        height="21"
        viewBox="0 0 28.028 21"
      >
        <g id="Group_748" data-name="Group 748">
          <path
            id="Path_316"
            data-name="Path 316"
            d="M350.985,391.9V403.15c-.01.014-.026.027-.03.042a2.571,2.571,0,0,1-2.8,2.086H325.791c-.091,0-.183,0-.274,0a2.515,2.515,0,0,1-2.557-2.5c-.005-3.892,0-6.784,0-10.676a1.87,1.87,0,0,1,.022-.2c.049.026.074.036.1.051l11.354,7.21a4.392,4.392,0,0,0,5.079,0q5.075-3.219,10.149-6.441C350.1,392.441,350.544,392.172,350.985,391.9Z"
            transform="translate(-322.958 -384.278)"
            fill="#949499"
          />
          <path
            id="Path_317"
            data-name="Path 317"
            d="M351,313.009a3.446,3.446,0,0,1-.325.79,3.2,3.2,0,0,1-.809.828q-5.707,3.655-11.442,7.272a2.546,2.546,0,0,1-2.879,0q-5.744-3.626-11.47-7.28a2.267,2.267,0,0,1-1-2.6,2.558,2.558,0,0,1,2.379-1.805c.082-.005.164-.006.246-.006h22.578a2.6,2.6,0,0,1,2.589,1.683c.052.146.091.3.136.445Z"
            transform="translate(-322.977 -310.204)"
            fill="#949499"
          />
        </g>
      </svg>
    ),
    url: "https://gmail.com",
    ariaLabel: "Email",
  },
];

export const images = [
  {
    src: "./covers/1.jpg",
    id: "co1im1",
    color: ["#5a81a2", "#1e3c60"],
    label: "exterior",
    name: "modern house",
  },
  {
    src: "./covers/2.jpg",
    id: "co1im2",
    color: ["#5a81a2", "#1e3c60"],
    label: "virtual tour",
    name: "modern house",
  },
  {
    src: "./covers/3.jpg",
    id: "co1im3",
    color: ["#5a81a2", "#1e3c60"],
    label: "interior",
    name: "classic cafe",
  },
  {
    src: "./covers/4.jpg",
    id: "co1im4",
    color: ["#5a81a2", "#1e3c60"],
    label: "cgi animation",
    name: "modern house",
  },
  {
    src: "./covers/5.jpg",
    id: "co1im5",
    color: ["#5a81a2", "#1e3c60"],
    label: "3d rendering",
    name: "modern house",
  },
];

export const homeInt = [
  {
    num: "01",
    id: "h01in1",
    h4: "Interior design",
    p: "",
    a: { text: "Learn more",  url:"/projects#In" },
    img: {
      cover: inter,
      colors: { right: "#82674C", left: "#E8DCD3", bg:"#b79b7eb3", txt:"#ffffff"},
      klass: "threeD",
    },
  },
  {
    num: "02",
    id: "h02in2",
    h4: "Exterior design",
    p: "",
    a: { text: "Learn more", url:"/projects#Ex" },
    img: {
      cover: ext,
      colors: { right: "#5A81A2", left: "#1F3D61", bg:"#325479cc" },
      klass: "int end",
    },
  },
];

export const homeExt = [
  {
    num: "01",
    id: "h21ex1",
    h4: "3D Rendering",
    p: "",
    a: { text: "Learn more", url:"/projects#3dr"},
    img: {
      cover: t3d,
      colors: { right: "#25261E", left: "#646659", bg:"#ec9a9eb3" },
      klass: "cgi",
    },
  },
  {
    num: "02",
    id: "h21ex2",
    h4: "Animation",
    p: "",
    a: { text: "Learn more", url:"/projects#Ani" },
    img: {
      cover: ani,
      colors: { right: "#73695C", left: "#E6E1DA", bg:"#b2a498cc"},
      klass: "ex",
    },
  },
  {
    num: "03",
    id: "h21ex3",
    h4: "Virtual tour",
    p: "",
    a: { text: "Learn more", url: "/projects#VR" },
    img: {
      cover: vr,
      colors: {right: "#736059", left: "#D9D4D1" , bg:"#59493ec2" },
     
      klass: "vr end",
    },
  },
];

export const servData = [
  {
    num: "01",
    id: "h21ex1",
    h4: "Interior design",
    p: "People want to feel good in a space. We can make it happen by creating environments that are pleasing, aesthetic, and functional.",
    a: { text: "View more", url: "/projects#In" },
    img: {
      cover: inter,
      colors: { right: "1", left: "1" },
      klass: "",
    },
  },
  {
    num: "02",
    id: "h21ex2",
    h4: "Exterior design",
    p: "Our responsibility to a project extends beyond the walls of the building. We always seize the opportunity to maximize the relationship between the structure and its external context.",
    a: { text: "Learn more", url: "/projects#Ex" },
    img: {
      cover: ext,
      colors: { right: "1", left: "1" },
      klass: "",
    },
  },
  {
    num: "03",
    id: "h21ex3",
    h4: "3D Rendering",
    p: "Photorealistic 3D rendering is a process performed by CGI artists with the help of professional software. The results of this process are photo-like 2D visuals. In the context of architecture, those visuals depict interiors and exteriors of buildings that are yet to be constructed.",
    a: { text: "Learn more", url: "/projects#3dr" },
    img: {
      cover: t3d,
      colors: { right: "1", left: "1" },
      klass: "",
    },
  },
  {
    num: "04",
    id: "h21ex4",
    h4: "CG Animation",
    p: "The architectural movie provides a highly immersive experience for presentation and property marketing. It shows surroundings, interior and exterior design, and walks the viewer through the property as if it were already built.",
    a: { text: "Learn more", url: "/projects#Ani" },
    img: {
      cover: ani,
      colors: { right: "1", left: "1" },
      klass: "",
    },
  },
  {
    num: "05",
    id: "h21ex5",
    h4: "Virtual tour",
    p: "A CGI platform that allows real estate professionals to give prospects and clients self-guided tours of properties and designs. The tours can also be VR-compatible and include information about the interior design products used.",
    a: { text: "Learn more", url: "/projects#VR" },
    img: {
      cover: vr,
      colors: { right: "1", left: "1" },
      klass: "end",
    },
  },
];


export const lgScreens = {
  needs: [
    'Architectural Design/Plan',
    'Interior design',
    'Exterior design',
    'Concept design',
    'Landscape design',
    'CG Animation/Movie',
    '3D Rendering',
    '3D Modeling',
    'SketchUp',
    'Virtual tour'
  ],
  docs: [
    'PDF Drawings',
    'AutoCAD Drawings',
    'Sketch',
    '3D Model',
    'Site photos',
    'Measurements',
    'Refrence',
    'Moodboard'
  ],
  budgets: [
    'Under 2K',
    '2-5K',
    '5-10K',
    '+10K',
    'Help me estimate'
  ]
}

export const smScreens = {
  needs: [
    'Interior',
    'Exterior',
    'Design',
    'Architecture',
    'Concept',
    '3D',
    'CG Animation',
    '3D Rendering',
    'SketchUp',
    'VR',
    'Virtual Tour',
    '3D Modeling',
    'Landscape',
  ],
  docs: [
    'PDF',
    'AutoCAD',
    'Drawings',
    'Sketch',
    'Refrence',
    '3D Model',
    'Measurements',
    'Moodboard',
    'Plan',
    'Elevation',
    'Site photos',
  ],
  budgets: [
    'Under 2K',
    '2-5K',
    '5-10K',
    '+10K',
    'Help me estimate'
  ]
}

export const prjData = [
  
  {
    id:"p15i1",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project15",
    a:{text:"View more", url:"pr15-1"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b79b7eb3"},
      url:"../assets/prj/prj15/p15-1.jpg"
    }
  },
  {
    id:"p15i2",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project15",
    a:{text:"View more", url:"pr15-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#aaa095b3"},
      url:"../assets/prj/prj15/p15-2.jpg"
    }
  },
  {
    id:"p15i3",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project15",
    a:{text:"View more", url:"pr15-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b79b7eb3"},
      url:"../assets/prj/prj15/p15-3.jpg"
    }
  },
  {
    id:"p15i4",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project15",
    a:{text:"View more", url:"pr15-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#aaa095b3"},
      url:"../assets/prj/prj15/p15-4.jpg"
    }
  },
  {
    id:"p15i5",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project15",
    a:{text:"View more", url:"pr15-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b79b7eb3"},
      url:"../assets/prj/prj15/p15-5.jpg"
    }
  },
  {
    id:"p15i6",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project15",
    a:{text:"View more", url:"pr15-6"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#aaa095b3"},
      url:"../assets/prj/prj15/p15-6.jpg"
    }
  },
  {
    id:"p15i7",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project15",
    a:{text:"View more", url:"pr15-7"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b79b7eb3"},
      url:"../assets/prj/prj15/p15-7.jpg"
    }
  },
  {
    id:"p15i8",
    name: 'Light & Warm',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project15",
    a:{text:"View more", url:"pr15-8"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#aaa095b3"},
      url:"../assets/prj/prj15/p15-8.jpg"
    }
  },
  {
    id:"p2i1",
    name: 'Cook in Heaven',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project2",
    a:{text:"View more", url:"pr2-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#a1a1a1b3"},
      url:"../assets/prj/prj2/p2-1.jpg"
    }
  },
  {
    id:"p2i2",
    name: 'Cook in Heaven',
    desc: 'Interior design, Rendering',
    tags:[ '3D Model',],
    family:"project2",
    a:{text:"View more", url:"pr2-2"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#a65c51cc"},
      url:"../assets/prj/prj2/p2-2.jpg"
    }
  },
  {
    id:"p1i1",
    name: 'Warm palette',
    desc: 'Interior design, Rendering',
    tags:['Interior','3D Rendering','3DRendering','Projects'],
    family:"project1",
    a:{text:"View more", url:"pr1-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#c4ab99d6"},
      url:"../assets/prj/prj1/p1-1.jpg"
    }
  },
  {
    id:"p1i2",
    name: 'Winter scent',
    desc: 'Exterior design, Rendering',
    tags:[ '3D Model'],
    family:"project1",
    a:{text:"View more", url:"pr1-2"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#a6b1b9cc"},
      url:"../assets/prj/prj1/p1-2.jpg"
    }
  },
  {
    id:"p16i1",
    name: 'Pastel Pink',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project16",
    a:{text:"View more", url:"pr16-1"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#ec9a9eb3"},
      url:"../assets/prj/prj16/p16-1.jpg"
    }
  },
  {
    id:"p16i2",
    name: 'Pastel Pink',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project16",
    a:{text:"View more", url:"pr16-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#ec9a9eb3"},
      url:"../assets/prj/prj16/p16-2.jpg"
    }
  },
  {
    id:"p14i1",
    name: 'Glenrose',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project14",
    a:{text:"View more", url:"pr14-1"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#bba387d4"},
      url:"../assets/prj/prj14/p14-1.jpg"
    }
  }, 
  {
    id:"p12i1",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project12",
    a:{text:"View more", url:"pr12-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#79614fcc"},
      url:"../assets/prj/prj12/p12-1.jpg"
    }
  },
  {
    id:"p12i2",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project12",
    a:{text:"View more", url:"pr12-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2b8c4cc"},
      url:"../assets/prj/prj12/p12-2.jpg"
    }
  },
  {
    id:"p12i3",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project12",
    a:{text:"View more", url:"pr12-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#79614fcc"},
      url:"../assets/prj/prj12/p12-3.jpg"
    }
  },
  {
    id:"p12i4",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project12",
    a:{text:"View more", url:"pr12-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2b8c4cc"},
      url:"../assets/prj/prj12/p12-4.jpg"
    }
  },
  {
    id:"p12i5",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project12",
    a:{text:"View more", url:"pr12-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#79614fcc"},
      url:"../assets/prj/prj12/p12-5.jpg"
    }
  },
  {
    id:"p12i6",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project12",
    a:{text:"View more", url:"pr12-6"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#79614fcc"},
      url:"../assets/prj/prj12/p12-6.jpg"
    }
  },
  {
    id:"p12i7",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project12",
    a:{text:"View more", url:"pr12-7"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2b8c4cc"},
      url:"../assets/prj/prj12/p12-7.jpg"
    }
  },
  {
    id:"p12i8",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project12",
    a:{text:"View more", url:"pr12-8"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#79614fcc"},
      url:"../assets/prj/prj12/p12-8.jpg"
    }
  },
  {
    id:"p12i9",
    name: 'Cozy Cafe',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project12",
    a:{text:"View more", url:"pr12-9"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2b8c4cc"},
      url:"../assets/prj/prj12/p12-9.jpg"
    }
  },
  {
    id:"p4i1",
    name: 'Nature Sound',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', 'Projects'],
    family:"project4",
    a:{text:"View more", url:"pr4-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#45473bb3"},
      url:"../assets/prj/prj4/p4-1.jpg"
    }
  },
  {
    id:"p4i2",
    name: 'Nature Sound',
    desc: 'Interior design, Rendering',
    tags:['3D Model',],
    family:"project4",
    a:{text:"View more", url:"pr4-2"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj4/p4-2.jpg"
    }
  },
  {
    id:"p4i3",
    name: 'Nature Sound',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', ],
    family:"project4",
    a:{text:"View more", url:"pr4-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#45473bb3"},
      url:"../assets/prj/prj4/p4-3.jpg"
    }
  },
  {
    id:"p4i4",
    name: 'Nature Sound',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', ],
    family:"project4",
    a:{text:"View more", url:"pr4-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#45473bb3"},
      url:"../assets/prj/prj4/p4-4.jpg"
    }
  },
  {
    id:"p4i5",
    name: 'Nature Sound',
    desc: 'Interior design, Rendering',
    tags:[ '3D Model',],
    family:"project4",
    a:{text:"View more", url:"pr4-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj4/p4-5.jpg"
    }
  },
  {
    id:"p4i6",
    name: 'Nature Sound',
    desc: 'Interior design, Rendering',
    tags:[ '3D Model',],
    family:"project4",
    a:{text:"View more", url:"pr4-6"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#2b313db5"},
      url:"../assets/prj/prj4/p4-6.jpg"
    }
  },
  {
    id:"p4i7",
    name: 'Nature Sound',
    desc: 'Interior design, Rendering',
    tags:[ '3D Model',],
    family:"project4",
    a:{text:"View more", url:"pr4-7"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#2b313db5"},
      url:"../assets/prj/prj4/p4-7.jpg"
    }
  },
  {
    id:"p11i1",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project11",
    a:{text:"View more", url:"pr11-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#b2a498cc"},
      url:"../assets/prj/prj11/p11-1.jpg"
    }
  },
  {
    id:"p11i2",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project11",
    a:{text:"View more", url:"pr11-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2a498cc"},
      url:"../assets/prj/prj11/p11-2.jpg"
    }
  },
  {
    id:"p11i3",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project11",
    a:{text:"View more", url:"pr11-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2a498cc"},
      url:"../assets/prj/prj11/p11-3.jpg"
    }
  },
  // {
  //   id:"p3i2",
  //   name: 'Villa pano',
  //   desc: 'Interior design, Rendering',
  //   tags:['Interior','Exterior', 'Animation', '3D Rendering','3DRendering', 'Concept', 'Virtual tour', '3D Model', 'Projects'],
  //   family:"project3",
  //   a:{text:"View more", url:"pr3-2"},
  //   img:{
  //     cover:"",
  //     colors:{right: "#000000", left:"#ffffff"},
  //     url:"../assets/prj/prj3/p3-2.jpeg"
  //   }
  // },
  {
    id:"p11i4",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project11",
    a:{text:"View more", url:"pr11-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2a498cc"},
      url:"../assets/prj/prj11/p11-4.jpg"
    }
  },
  {
    id:"p11i5",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project11",
    a:{text:"View more", url:"pr11-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2a498cc"},
      url:"../assets/prj/prj11/p11-5.jpg"
    }
  },
  {
    id:"p11i6",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project11",
    a:{text:"View more", url:"pr11-6"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2a498cc"},
      url:"../assets/prj/prj11/p11-6.jpg"
    }
  },
  {
    id:"p11i7",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project11",
    a:{text:"View more", url:"pr11-7"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#b2a498cc"},
      url:"../assets/prj/prj11/p11-7.jpg"
    }
  },
  {
    id:"p11i8",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project11",
    a:{text:"View more", url:"pr11-8"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#9caab5cc"},
      url:"../assets/prj/prj11/p11-8.jpg"
    }
  },
  {
    id:"p11i9",
    name: 'Villa pano',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project11",
    a:{text:"View more", url:"pr11-9"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#9caab5cc"},
      url:"../assets/prj/prj11/p11-9.jpg"
    }
  },
  {
    id:"p6i1",
    name: 'Winter Scent',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', 'Projects'],
    family:"project6",
    a:{text:"View more", url:"pr6-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#325479cc"},
      url:"../assets/prj/prj6/p6-1.jpg"
    }
  },
  {
    id:"p6i2",
    name: 'Winter Scent',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project6",
    a:{text:"View more", url:"pr6-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#6b6d70cc"},
      url:"../assets/prj/prj6/p6-2.jpg"
    }
  },
  {
    id:"p6i3",
    name: 'Winter Scent',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project6",
    a:{text:"View more", url:"pr6-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#9b9b9bcc"},
      url:"../assets/prj/prj6/p6-3.jpg"
    }
  },
  {
    id:"p6i4",
    name: 'Winter Scent',
    desc: 'Interior design, Rendering',
    tags:['3D Model',],
    family:"project6",
    a:{text:"View more", url:"pr6-4"},
    // iframeSrc: 'https://am-arc.com/vr/vr-2/',
    img:{
      cover:"",
      colors:{right: "#000000", left:"#6b6d70cc"},
      url:"../assets/prj/prj6/p6-4.jpg"
    }
  },
  {
    id:"p7i1",
    name: 'Calm & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project7",
    a:{text:"View more", url:"pr7-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#ccc2c0cc"},
      url:"../assets/prj/prj7/p7-1.jpg"
    }
  },
  {
    id:"p7i2",
    name: 'Calm & Warm',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project7",
    a:{text:"View more", url:"pr7-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#a0a9bbcc"},
      url:"../assets/prj/prj7/p7-2.jpg"
    }
  },
  {
    id:"p7i3",
    name: 'Calm & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project7",
    a:{text:"View more", url:"pr7-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#ccc2c0cc"},
      url:"../assets/prj/prj7/p7-3.jpg"
    }
  },
  {
    id:"p7i4",
    name: 'Calm & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project7",
    a:{text:"View more", url:"pr7-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#ccc2c0cc"},
      url:"../assets/prj/prj7/p7-4.jpg"
    }
  },
  {
    id:"p7i5",
    name: 'Calm & Warm',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project7",
    a:{text:"View more", url:"pr7-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#ccc2c0cc"},
      url:"../assets/prj/prj7/p7-5.jpg"
    }
  },
  {
    id:"p8i1",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', 'Projects'],
    family:"project8",
    a:{text:"View more", url:"pr8-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#507798cc"},
      url:"../assets/prj/prj8/p8-1.jpg"
    }
  },
  {
    id:"p8i2",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', ],
    family:"project8",
    a:{text:"View more", url:"pr8-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#507798cc"},
      url:"../assets/prj/prj8/p8-2.jpg"
    }
  },
  {
    id:"p8i3",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project8",
    a:{text:"View more", url:"pr8-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#507798cc"},
      url:"../assets/prj/prj8/p8-3.jpg"
    }
  },
  {
    id:"p8i4",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project8",
    a:{text:"View more", url:"pr8-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#507798cc"},
      url:"../assets/prj/prj8/p8-4.jpg"
    }
  },
  {
    id:"p8i5",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project8",
    a:{text:"View more", url:"pr8-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#507798cc"},
      url:"../assets/prj/prj8/p8-5.jpg"
    }
  },
  {
    id:"p8i6",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project8",
    a:{text:"View more", url:"pr8-6"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#a99e95cc"},
      url:"../assets/prj/prj8/p8-6.jpg"
    }
  },
  {
    id:"p8i7",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project8",
    a:{text:"View more", url:"pr8-7"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#a99e95cc"},
      url:"../assets/prj/prj8/p8-7.jpg"
    }
  },
  {
    id:"p8i8",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project8",
    a:{text:"View more", url:"pr8-8"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#a99e95cc"},
      url:"../assets/prj/prj8/p8-8.jpg"
    }
  },
  {
    id:"p8i9",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project8",
    a:{text:"View more", url:"pr8-9"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#2b313db5"},
      url:"../assets/prj/prj8/p8-9.jpg"
    }
  },
  {
    id:"p8i10",
    name: 'Live Blue',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project8",
    a:{text:"View more", url:"pr8-10"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#575757cc"},
      url:"../assets/prj/prj8/p8-10.jpg"
    }
  },
  {
    id:"p9i1",
    name: 'Green Heaven',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', 'Projects'],
    family:"project9",
    a:{text:"View more", url:"pr9-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#606255cc"},
      url:"../assets/prj/prj9/p9-1.jpg"
    }
  },
  {
    id:"p9i2",
    name: 'Green Heaven',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project9",
    a:{text:"View more", url:"pr9-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#c5bab6cc"},
      url:"../assets/prj/prj9/p9-2.jpg"
    }
  },
  {
    id:"p9i3",
    name: 'Green Heaven',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project9",
    a:{text:"View more", url:"pr9-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#606255cc"},
      url:"../assets/prj/prj9/p9-3.jpg"
    }
  },
  {
    id:"p9i4",
    name: 'Green Heaven',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project9",
    a:{text:"View more", url:"pr9-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#606255cc"},
      url:"../assets/prj/prj9/p9-4.jpg"
    }
  },
  {
    id:"p9i5",
    name: 'Green Heaven',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project9",
    a:{text:"View more", url:"pr9-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#45698dcc"},
      url:"../assets/prj/prj9/p9-5.jpg"
    }
  },
  {
    id:"p10i1",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project10",
    a:{text:"View more", url:"pr10-1"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#707a71cc"},
      url:"../assets/prj/prj10/p10-1.jpg"
    }
  },
  {
    id:"p10i2",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#707a71cc"},
      url:"../assets/prj/prj10/p10-2.jpg"
    }
  },
  {
    id:"p10i3",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#678da4cc"},
      url:"../assets/prj/prj10/p10-3.jpg"
    }
  },
  {
    id:"p10i4",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#767676cc"},
      url:"../assets/prj/prj10/p10-4.jpg"
    }
  },
  {
    id:"p10i5",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#647996cc"},
      url:"../assets/prj/prj10/p10-5.jpg"
    }
  },
  {
    id:"p10i6",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-6"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#70a8bccc"},
      url:"../assets/prj/prj10/p10-6.jpg"
    }
  },
  {
    id:"p10i7",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-7"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#e0938eb3"},
      url:"../assets/prj/prj10/p10-7.jpg"
    }
  },
  {
    id:"p10i8",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-8"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#70a8bccc"},
      url:"../assets/prj/prj10/p10-8.jpg"
    }
  },
  {
    id:"p10i9",
    name: 'Sweet Basin',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project10",
    a:{text:"View more", url:"pr10-9"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#70a8bccc"},
      url:"../assets/prj/prj10/p10-9.jpg"
    }
  },
  {
    id:"p13i1",
    name: 'Round Retail',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering','Projects'],
    family:"project13",
    a:{text:"View more", url:"pr13-1"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#d4aa8fcc"},
      url:"../assets/prj/prj13/p13-1.jpg"
    }
  },
  {
    id:"p13i2",
    name: 'Round Retail',
    desc: 'Interior design, Rendering',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"project13",
    a:{text:"View more", url:"pr13-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#d4aa8fcc"},
      url:"../assets/prj/prj13/p13-2.jpg"
    }
  },
  {
    id:"p3i1",
    name: 'Nature Calls',
    desc: 'Interior design, Rendering',
    tags:[ 'Exterior','3D Rendering','3DRendering', 'Projects' ],
    family:"project3",
    a:{text:"View more", url:"pr3-1"},
    // iframeSrc: 'https://am-arc.com/vr/vr-1/',
    img:{
      cover:true,
      colors:{right: "#000000", left:"#7c7c7ccc"},
      url:"../assets/prj/prj3/p3-1.jpg"
    }
  },
  {
    id:"p3i2",
    name: 'Nature Calls',
    desc: 'Interior design, Rendering',
    tags:['3D Model',],
    family:"project3",
    a:{text:"View more", url:"pr3-2"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj3/p3-2.jpg"
    }
  },
  {
    id:"p3i3",
    name: 'Nature Calls',
    desc: 'Interior design, Rendering',
    tags:[ 'Exterior','3D Rendering','3DRendering',],
    family:"project3",
    a:{text:"View more", url:"pr3-3"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#7c7c7ccc"},
      url:"../assets/prj/prj3/p3-3.jpg"
    }
  },
  {
    id:"p3i4",
    name: 'Nature Calls',
    desc: 'Interior design, Rendering',
    tags:['3D Model', ],
    family:"project3",
    a:{text:"View more", url:"pr3-4"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj3/p3-4.jpg"
    }
  },
  {
    id:"p3i5",
    name: 'Nature Calls',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project3",
    a:{text:"View more", url:"pr3-5"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#7c7c7ccc"},
      url:"../assets/prj/prj3/p3-5.jpg"
    }
  },
  {
    id:"p3i6",
    name: 'Nature Calls',
    desc: 'Interior design, Rendering',
    tags:['3D Model',],
    family:"project3",
    a:{text:"View more", url:"pr3-6"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj3/p3-6.jpg"
    }
  },
  {
    id:"p3i7",
    name: 'Nature Calls',
    desc: 'Interior design, Rendering',
    tags:['Plan'],
    family:"project3",
    a:{text:"View more", url:"pr3-7"},
    img:{
      cover:false,
      colors:{right: "#000000", left:"#7c7c7ccc"},
      url:"../assets/prj/prj3/p3-7.jpg"
    }
  },
  {
    id:"p5i1",
    name: 'Modular Living',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', 'Projects'],
    family:"project5",
    a:{text:"View more", url:"pr5-1"},
    img:{
      cover:true,
      colors:{right: "#000000", left:"#7c7c7ccc"},
      url:"../assets/prj/prj5/p5-1.jpg"
    }
  },
  {
    id:"p5i2",
    name: 'Modular Living',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project5",
    a:{text:"View more", url:"pr5-2"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj5/p5-2.jpg"
    }
  },
  {
    id:"p5i3",
    name: 'Modular Living',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering', ],
    family:"project5",
    a:{text:"View more", url:"pr5-3"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#7c7c7ccc"},
      url:"../assets/prj/prj5/p5-3.jpg"
    }
  },
  {
    id:"p5i4",
    name: 'Modular Living',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project5",
    a:{text:"View more", url:"pr5-4"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj5/p5-4.jpg"
    }
  },
  {
    id:"p5i5",
    name: 'Modular Living',
    desc: 'Interior design, Rendering',
    tags:['Exterior','3D Rendering','3DRendering',],
    family:"project5",
    a:{text:"View more", url:"pr5-5"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#7c7c7ccc"},
      url:"../assets/prj/prj5/p5-5.jpg"
    }
  },
  {
    id:"p5i6",
    name: 'Modular Living',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project5",
    a:{text:"View more", url:"pr5-6"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#92483fcc"},
      url:"../assets/prj/prj5/p5-6.jpg"
    }
  },
  {
    id:"p5i7",
    name: 'Modular Living',
    desc: 'Interior design, Rendering',
    tags:['3D Model'],
    family:"project5",
    a:{text:"View more", url:"pr5-7"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#2b313db5"},
      url:"../assets/prj/prj5/p5-7.jpg"
    }
  },
  {
    id:"p17i2",
    name: 'vr',
    desc: 'Interior design, Rendering',
    tags:['Virtual tour'],
    family:"project17",
    a:{text:"View more", url:"pr17-2"},
    iframeSrc: 'https://am-arc.com/vr/vr-1/',
  },
  {
    id:"p17i1",
    name: 'vr',
    desc: 'Interior design, Rendering',
    tags:['Virtual tour'],
    family:"project17",
    a:{text:"View more", url:"pr17-1"},
    iframeSrc: 'https://am-arc.com/vr/vr-2/',
  },
  {
    id:"p18i1",
    name: 'Cozy Cafe',
    desc: 'Interior design, Animation',
    tags:['Animation'],
    family:"Animation",
    a:{text:"View more", url:"pr17-1", imgUrl:"../assets/prj/prj12/p12-1.jpg"},
    vidid: '34hD4u8WFuI',
    iframeSrc: true,
  },
  {
    id:"p18i2",
    name: 'Vlla pano',
    desc: 'Interior design, Animation',
    tags:['Animation'],
    family:"Animation",
    a:{text:"View more", url:"pr17-1", imgUrl:"../assets/prj/prj11/p11-1.jpg"},
    vidid: "xgWT3ZnPa40",
    iframeSrc: true,

   
  },
  {
    id:"p18i3",
    name: 'Pastel Pink',
    desc: 'Interior design, Animation',
    tags:['Animation'],
    family:"Animation",
    a:{text:"View more", url:"pr17-1", imgUrl:"../assets/prj/prj16/p16-1.jpg"},
    vidid: "NN_bmDgHre8",
    iframeSrc: true,

   
  },
  {
    id:"p18i4",
    name: 'Light & Warm',
    desc: 'Interior design, Animation',
    tags:['Animation'],
    family:"Animation",
    a:{text:"View more", url:"pr17-1", imgUrl:"../assets/prj/prj15/p15-1.jpg"},
    vidid: "xtcLIzJMSrc",
    iframeSrc: true,

   
  },
  {
    id:"p18i5",
    name: 'Beach Villa',
    desc: 'Exterior design, Concept design',
    tags:['Concept'],
    family:"Concept",
    a:{text:"View more", url:"pr17-1", imgUrl:"../assets/prj/prj18/018.jpg"},
    vidid: "3BM6ZFk1JPU",
    iframeSrc: true,
   
  },
  {
    id:"p18i6",
    name: 'N.B villa',
    desc: 'Interior/Exterior, Concept design',
    tags:['Concept'],
    family:"Concept",
    a:{text:"View more", url:"pr17-1", imgUrl:"../assets/prj/prj18/017.jpg"},
    vidid: "17hmW5GG7P8",
    iframeSrc: true,
   
  },
  {
    id:"p18i7",
    name: 'L.A hospital',
    desc: 'Exterior design, Concept design',
    tags:['Concept'],
    family:"Concept",
    a:{text:"View more", url:"pr17-1",  imgUrl:"../assets/prj/prj18/019.jpg"},
    vidid:"BcsbQNBj1kM",
    iframeSrc: true,
   
  },
  {
    id:"p18i8",
    name: "Khalili's unit",
    desc: 'Interior design, Concept design',
    tags:['Concept'],
    family:"Concept",
    a:{text:"View more", url:"pr17-1",  imgUrl:"../assets/prj/prj18/020.jpg"},
    vidid:"FiRRKVRyY2Q",
    iframeSrc: true,
  },
  // {
  //   id:"p18i8",
  //   name: 'animation',
  //   desc: 'Interior design, Rendering',
  //   tags:['Animation'],
  //   family:"Animation",
  //   a:{text:"View more", url:"pr17-1"},
  //   iframeSrc: 'https://www.youtube.com/embed/34hD4u8WFuI',
   
  // },

  
]
